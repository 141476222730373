
.ql-align-right {
  @apply text-right;
}
.ql-align-left {
  @apply text-left;
}
.ql-align-center {
  @apply text-center;
}
.ql-align-justify {
  @apply text-justify;
}
