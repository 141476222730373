html, body {
    scroll-behavior: smooth
}

[x-cloak] {
    display: none;
}

.ql-align-right {
    @apply text-right;
}

.ql-align-left {
    @apply text-left;
}

.ql-align-center {
    @apply text-center;
}

.ql-align-justify {
    @apply text-justify;
}
.ql-size-large {
    @apply text-2xl;
}
.btn {
    @apply inline-block items-center text-center justify-center px-8 py-3 text-base font-medium text-white min-w-max;
}

.btn-primary {
    @apply bg-gradient-to-r from-[#1839b2] to-[#0057c8] rounded-lg px-3 py-1 lg:px-10 lg:py-2 text-white uppercase font-bold my-2 lg:my-5 hover:from-[#01c29a] hover:to-[#1839b2] hover:text-white;
}

.btn-secondary {
    @apply bg-[#01c29a] rounded-lg px-3 py-1 lg:px-10 lg:py-2 text-white uppercase font-bold my-2 lg:my-5 hover:bg-[#007960] hover:text-white;
}

.btn-white {
    @apply bg-[#ffffff] rounded-lg px-3 py-1 lg:px-10 lg:py-2 text-[#0057c8] hover:text-[#0cbaa4] uppercase font-bold my-2 lg:my-5 hover:bg-[#f0f0f0];
}

.top_logo {
    //min-height:55px;
    @apply w-3/4;
}

.gsc-adBlock {
    @apply hidden #{!important};
}

.splide__pagination__page {
    @apply bg-gray-300;
}


.register_form, .contact_form, .webinar_form {
    .required {
        &:after {
            content: ' *';
        }

        @apply after:text-danger-500;
    }
}

.contact_info {
    h5 {
        @apply text-[#0057c8] font-bold text-2xl pb-2 lg:pb-4;
    }

    ul {
        @apply border-t border-gray-300 py-2 lg:py-4;
    }
}

.talent_solutions {
    @apply container;
    .box_container {
        @apply grid lg:grid-cols-2 grid-cols-1 gap-4 w-full;
        .box_heading {
            @apply lg:col-span-2 my-4;
            h5 {
                @apply text-2xl font-semibold py-2 xl:py-4  text-[#333];
            }

            p {
                @apply text-[#656565] text-base font-medium;
            }
        }

        .box_icon_inside {
            @apply grid grid-cols-1 lg:grid-cols-2 place-content-center gap-4 justify-items-center;
            .box_item {
                @apply rounded bg-white p-5 rounded-xl shadow-md flex flex-col justify-center self-center items-center h-full text-center;
                .b_content {
                    @apply py-2 min-h-[9rem] h-32;
                }

                .icon {
                    img {
                        @apply h-[110px];
                    }
                }

                .b_heading {
                    @apply text-lg font-bold py-2 text-[#333] text-center;
                }
            }
        }
    }
}

.age_of_ai {
    @apply relative my-10 container;
    .box_heading {
        @apply absolute max-w-max top-0 left-1/2 -translate-x-1/2;
        .content {
            @apply bg-[#01c29a] text-white text-center px-6 py-4 text-xl lg:text-3xl rounded-2xl font-light;
            h5 {
                @apply text-2xl lg:text-4xl font-semibold;
            }
        }
    }

    .box_container {
        @apply grid grid-cols-1 lg:grid-cols-2 gap-10 pt-10;
        .box_image img {
            @apply rounded-2xl h-128 max-h-128 bg-cover object-cover bg-fixed;
        }

        .title_heading {
            @apply text-center mt-2;
            h3 {
                @apply text-[#0e50dc] font-bold text-2xl md:text-3xl lg:text-4xl font-serif py-2;
            }
        }

        .box_content {
            @apply text-center text-gray-light;
        }
    }
}

.home_contact_us {
    .cta_content {
        @apply w-1/2 lg:w-1/4 mx-auto justify-center;
        div:first-child {
            @apply flex flex-1 gap-4;
            a {
                @apply flex-1 grow;
            }
        }
    }
}

.contact_us {

    .box_heading {
        @apply text-xl lg:text-[40px] text-white  pt-10 pb-6 leading-relaxed;
        strong {
            @apply font-bold text-2xl lg:text-[45px] leading-tight;
        }

        .softbreak {
            @apply hidden md:block;
        }
    }

    .cta_content {
        @apply pb-10 text-center;
    }
}

.box_by_the_numbers {
    @apply py-10 relative;
    h2 {
        @apply text-3xl font-bold text-gray-dark py-10;
    }

    .bg_background {
        @apply -mt-24;
        .box_icon {
            @apply pt-32 pb-4;
        }
    }

    .text_content {
        @apply container;
        ul {
            @apply list-outside list-none border shadow drop-shadow-xl rounded-2xl bg-white container lg:grid  grid-cols-2 gap-x-24 gap-y-5 pl-10 pr-8 py-5;
            li {
                &:before {
                    content: ' ';
                    border: 5px solid #d1d1d1;
                    position: absolute;
                    width: 12px;
                    height: 12px;
                    margin-left: -25px;
                    margin-top: 5px;
                    padding: 5px;
                }

                @apply before:bg-[#733edb] before:rounded-full pt-0 mt-0 leading-loose justify-items-start justify-start text-justify animated ;
            }
        }
    }

}

.box_our_partnership {
    .box_heading {
        @apply text-3xl font-bold text-gray-light py-6;
    }

    .text_content {
        @apply text-lg text-gray-light pb-5;
    }

    .bg_background {
        @apply border-b-[#ccc] border-t-[#ccc] border-opacity-25 border-b-4 border-t-4 py-8 container my-2;
    }

    .trusted_by {
        @apply text-3xl text-gray-dark py-10 w-[20rem] h-[20rem] lg:w-[400px] lg:h-[400px] relative ;
        .inner {
            @apply flex absolute justify-items-center items-center flex-col border-2 border-gray-light rounded-full p-10 m-5 z-40 h-[80%] w-[80%] lg:top-[15%] lg:left-[5%] top-[16%] left-[3%];
        }

        canvas {
            @apply w-[20rem] h-[20rem] lg:w-[400px] lg:h-[400px] #{!important};
        }
    }

    .trusted_number {
        @apply text-4xl lg:text-9xl font-bold ;
    }

    .content_footer {
        @apply text-gray-dark py-6 mt-4;
        h2 {
            @apply font-bold text-2xl py-4;
        }
    }
}

.box_assessment_data {
    @apply h-fit container;
    .box_heading {
        @apply text-lg text-gray-light;
    }

    .cta_content {
        @apply text-center mx-auto;
    }
}

.box_list_solutions {
    @apply py-4;
    .box_heading {
        @apply bg-gray-600 text-3xl text-white lg:px-24 lg:py-14 px-4 py-2 font-semibold rounded-tl-4xl rounded-tr-4xl;
    }

    .icon_item {
        @apply bg-[#f4f7f9] rounded-bl-4xl rounded-br-4xl py-12 px-8 container lg:grid grid-flow-col-dense grid-rows-3 gap-x-4 gap-y-8;
        .b_content {
            @apply border-l-4 border-l-gray-300 pl-8 pr-10 leading-none;
            .box_title {
                @apply text-lg font-bold text-[#2355c1];
            }

            .box_content {
                @apply leading-normal text-gray-light;
            }
        }
    }
}

.box_list_solutions4 {
    @apply py-4;
    .box_heading {
        @apply bg-gray-600 text-3xl text-white px-4 lg:px-24 py-2 lg:py-14 font-semibold rounded-tl-4xl rounded-tr-4xl;
    }

    .icon_item {
        @apply bg-[#f4f7f9] rounded-bl-4xl rounded-br-4xl py-12 px-8 container lg:grid grid-flow-col-dense grid-rows-4 gap-x-4 gap-y-8;
        .b_content {
            @apply pr-10 leading-none;
            .box_title {
                @apply text-lg font-bold text-[#2355c1] border-l-4 border-l-gray-300 pl-8 ;
            }

            .box_content {
                @apply leading-normal text-gray-light border-l-4 border-l-gray-300 pl-8 ;
            }
        }
    }
}

.early_career_solutions {
    @apply container py-10;
    .box_heading {
        @apply text-4xl font-bold;
        h4 {
            @apply text-[#263853] text-[2rem] font-light py-2;
            strong {
                @apply font-bold;
            }
        }
    }

    .icon_item {
        @apply grid grid-cols-1 lg:grid-cols-2 gap-10 text-gray-light text-lg py-10 px-4 lg:px-0;
        .box_title {
            @apply text-[#2355c1] font-bold before:bg-[#0059BE] before:rounded-full ;
            &:before {
                content: ' ';
                border: 5px solid #CBD2DC;
                position: absolute;
                width: 12px;
                height: 12px;
                margin-left: -25px;
                margin-top: 5px;
                padding: 5px;
            }
        }
    }
}

.consulting_solutions {
    @apply relative;
    background: linear-gradient(to bottom, #000000 0%, #000000 40%, #ffffff 40%, #ffffff 100%);

    .box_heading {
        @apply text-white text-3xl pt-12 font-bold pb-4;
    }

    .b_box {
        @apply px-4 py-8
    }

    .box_container {
        @apply container bg-white rounded-2xl drop-shadow-xl shadow-xl;
        .box_heading {
            @apply text-gray-light text-2xl font-medium bg-white text-center mx-auto py-8;
        }
    }

    .box_item {
        .b_heading {
            @apply font-bold pt-8 pb-2 text-center mx-auto;
        }

        &:nth-child(1) {
            .b_heading {
                @apply text-[#1eba97] relative;
            }
        }

        &:nth-child(n+2):nth-child(-n+3) {
            .b_heading {
                @apply text-[#209bab] w-max;
            }
        }

        &:nth-child(n+4) {
            .b_heading {
                @apply text-[#2b60ad];
            }
        }

        @apply bg-white;

    }

    .box_icon_inside {
        @apply lg:grid grid-flow-col gap-0 justify-center bg-white text-center relative;
        .box_item {
            @apply justify-center flex flex-col;
        }

        .icon {
            //@apply flex-1 mx-auto justify-items-center;
            @apply mx-auto h-48 justify-end items-end content-end flex relative;
        }

        .b_content {
            @apply grow text-sm px-4;
        }

        .box_image {
            @apply absolute top-[200px] left-0 hidden lg:block;
        }
    }

    ul {
        @apply text-white grid grid-cols-1 lg:grid-cols-3 gap-4 container my-4;
        li {
            @apply text-lg text-white before:bg-[#0059BE] before:rounded-full lg:pl-4;
            &:before {
                content: ' ';
                border: 5px solid #ffffff;
                position: absolute;
                width: 12px;
                height: 12px;
                margin-left: -40px;
                margin-top: 5px;
                padding: 5px;
            }
        }
    }
}

._container_how {

    .box_heading {
        @apply text-white text-3xl pt-12 font-bold pb-4;
    }

    .text_content {
        @apply text-white;
    }

    .box_container {
        background: linear-gradient(to bottom, transparent 0%, transparent 20%, #f3f5f7 20%, #f3f5f7 100%);

        .b_box {
            @apply container px-4 py-8 mt-4 rounded-2xl drop-shadow-lg shadow-xl bg-white;
        }

        .box_heading {
            @apply text-gray-light text-2xl font-medium bg-white text-center mx-auto py-8;
        }
    }

    .box_item {
        .b_heading {
            @apply font-bold pt-8 pb-2 text-center mx-auto;
        }

        @apply bg-white;
    }

    .how_others_see_us {
        @apply lg:flex items-center justify-center justify-items-center space-x-8;
        .box_icon_inside {
            @apply flex justify-center;
        }

        .box_content {
            @apply py-2;
            ul {
                li {
                    @apply text-lg before:bg-checkmark py-2;
                    &:before {
                        content: ' ';
                        display: inline-block;
                        height: 25px;
                        width: 25px;
                    }

                }
            }
        }
    }

}

._product_innovations {
    .block_content {
        @apply py-8;
        .heading {
            @apply py-3 text-left text-2xl font-bold text-black #{!important};
        }

        .text_content {
            @apply container text-justify text-lg leading-relaxed #{!important};
            p {
                @apply py-2;
            }
        }

        .box_container {
            @apply grid grid-cols-1 lg:grid-cols-3 gap-4 container;
            .b_box {
                @apply flex flex-col items-center justify-between text-center p-2 rounded-2xl bg-[#f4f7f9];
            }

        }
    }

    ._boxstyle_science_innovation {
        .b_content .title {
            @apply font-bold text-xl;
        }

        .b_modal {
            h6 {
                @apply py-2 font-medium;
            }
        }
    }
}

._data_science {
    @apply py-4;
    .box_heading {
        .heading {
            @apply py-3 text-left text-2xl font-bold text-black #{!important};
        }
    }

    .block_content {
        .text_content {
            @apply container text-justify #{!important};
        }
    }

    .box_container {
        @apply container;
        .box_icon_inside {
            @apply lg:grid grid-cols-1 lg:grid-cols-3 gap-8 py-8 place-content-center;
            .box_item {
                @apply mx-auto;
                img {
                    @apply rounded-md;
                }
            }
        }
    }

    ._boxstyle_science_data {
        .title_heading {
            @apply text-2xl text-gray-dark font-medium py-4;
        }

        .btn_group {
            @apply grid grid-cols-1 lg:grid-cols-3 gap-4 justify-end items-center;
            .btn_label {
                @apply rounded-2xl bg-gray-300 text-center mx-auto px-4 pt-8 pb-2;
            }
        }
    }
}

._talent_analytics {
    .block_content {
        @apply py-8;
        .heading {
            @apply py-3 text-left text-2xl font-bold text-black #{!important};
        }

        .box_container {
            @apply container;
        }
    }

    .box_icon_inside {
        @apply flex flex-col gap-y-12 py-8;
        .box_item {
            @apply text-justify;
            .label {
                @apply text-2xl font-bold;
            }
        }
    }
}

._list_consulting {
    @apply py-4;

    .card-header {
        @apply px-4 xl:px-8;
        h5 {
            @apply text-2xl py-4;
        }
    }

    .card-body {
        @apply px-4 xl:px-8;

        .box_container {
            @apply flex flex-col space-y-8;

            ._boxstyle_image_right {
                .b_box {
                    @apply lg:grid grid-flow-row-dense grid-cols-1 lg:grid-cols-3 xl:gap-8 gap-4 flex-1;

                    .b_content {
                        @apply col-span-2;

                        .title_heading {
                            @apply border-b-gray-400 border-b-2  py-2;
                            h5 {
                                @apply text-xl text-[#0057c8] font-medium;
                            }

                            p {
                                @apply italic text-gray-light;
                            }
                        }

                        .box_content {
                            @apply py-4 text-justify;
                        }
                    }

                    .b_image {
                        @apply py-2;

                        .box_image img {
                            @apply rounded-xl;
                        }
                    }
                }
            }
        }
    }
}


.career_compass, ._section_personality_performance {
    @apply py-4 lg:py-10;
    .box_heading {
        @apply text-3xl font-bold py-8;
    }

    .box_container {
        @apply container lg:flex lg:flex-row ;
        .bordered_box_title {
            @apply flex justify-items-center justify-center content-center;
            .label_title {
                @apply py-1 px-2;
            }
        }

        .diff-plus {
            @apply after:bg-[#CED7DD] relative after:hidden after:lg:inline-block after:content-[''] after:absolute after:bottom-[2rem] after:left-1/2 after:w-[2px] after:transform after:translate-x-[-50%] after:h-[calc(100%-10rem)]
        }

        .diff-plus span {
            border: 8px solid #fff;
            justify-content: center;
            font-size: 4.5rem;
            line-height: 0.8;
            font-weight: 600;
            @apply absolute top-[1rem] left-[-40px] text-white rounded-full flex content-center font-bold w-[78px] h-[78px];
        }
    }

    .b_box {
        @apply mx-4;
        .box_content {
            @apply py-4;
            ul {
                @apply list-disc list-outside ml-[20px] leading-loose text-justify;
            }

            .box_icon_inside {
                @apply p-2;
            }
        }

        //.diff-plus:after{
        //    content: "";
        //    display: inline-block;
        //    position: absolute;
        //    bottom: 2rem;
        //    left: 50%;
        //    width: 2px;
        //    transform: translateX(-50%);
        //    height: calc(100% - 10rem);
        //    background-color: #CED7DD;
        //}

        //&:nth-child(1) {
        //    .box_content {
        //        @apply relative py-4 after:border-gray-light after:border-r after:absolute after:right-[-30px] after:top-[12px] after:h-[90%] after:lg:block after:hidden
        //    }
        //}

        .two_side_plus {
            .box_heading {
                @apply bg-[#1f262c] text-white rounded-xl text-center py-8;
            }

        }
    }
}

.career_compass {
    .diff-plus span {
        @apply bg-gradient-to-br from-[#01c29a] to-[#0057c8];
    }
}

._section_personality_performance {
    .diff-plus span {
        @apply bg-[#01c29a];
    }
}

._why_hogan {
    @apply lg:py-10 py-2;
    .box_heading {
        @apply pt-10 pb-2 text-3xl font-bold;
    }

    .text_content {
        @apply text-lg pb-6 pt-4 font-medium;
    }

    .icon_item {
        @apply container lg:flex justify-center;
    }

    .b_content {
        @apply lg:w-1/4 w-full py-2;
        &:nth-child(even) {
            background: linear-gradient(to bottom, #1f262c 0%, #1f262c 50%, #ffffff 50%, #ffffff 100%);
        }

        &:nth-child(odd) {
            background: linear-gradient(to bottom, #41464a 0%, #41464a 50%, #ffffff 50%, #ffffff 100%);
        }

        @apply mx-auto items-center content-center flex flex-col gap-0;
        .box_title {
            @apply text-white font-bold pt-2 pb-4;
        }

        .box_content {
            @apply text-center;
        }

        .icon {
            @apply p-8 border-[1rem] border-white bg-[#01c29a] rounded-full w-max mx-auto inline-flex;
        }
    }
}

._heading_benefit {
    .box_container {
        @apply relative container xl:px-40 px-4;
        .benefit_heading {
            @apply lg:grid grid-cols-2 gap-y-4 gap-x-12 content-center;
            .box_image {
                @apply col-span-2;
                img {
                    @apply max-w-full object-contain -mt-10 xl:-mt-20 left-0 right-0 rounded-2xl;
                }
            }

            .title_heading {
                @apply relative flex items-center font-bold text-3xl text-[#333];
            }

            .box_content {
                @apply flex items-center ;
                ul {
                    @apply list-outside list-none pl-12 xl:px-0;
                    li {
                        @apply before:bg-[#01c29a] before:border-opacity-70 before:rounded-full pt-0 mt-0 leading-loose justify-items-start justify-start text-justify animated ;
                        &:before {
                            content: ' ';
                            border: 5px solid #d1d1d1;
                            position: absolute;
                            margin-left: -40px;
                            margin-top: 5px;
                            padding: 7px;
                        }
                    }
                }
            }
        }

    }
}

._section_4_cols {
    @apply container;
    .icon_item {
        @apply grid grid-cols-2 lg:grid-cols-4 gap-10 py-4;
        .b_content {
            @apply bg-black rounded-tl-4xl rounded-br-4xl text-white py-6 px-4 text-center;
            .box_title {
                @apply xl:text-[3.125rem] text-2xl font-bold py-4 text-[#01c29a];
            }
        }
    }
}

._section_resources {
    @apply my-6 py-6 container;
    ._boxstyle_box_list {
        @apply border shadow-lg drop-shadow-md rounded-2xl xl:px-12 p-4 xl:py-8 xl:flex flex-row xl:space-x-8;
        .title_heading {
            @apply w-full xl:w-5/12 xl:flex items-center justify-start text-2xl xl:text-3xl font-bold;
        }

        .box_content {
            @apply xl:w-7/12 w-full px-2;
            ul {
                @apply xl:grid grid-cols-2 gap-4 text-base text-primary-300;
            }
        }
    }
}

._section_around_the_world {
    @apply text-white bg-black bg-opacity-50;
    .block_content {
        @apply py-12 xl:bg-right-top bg-opacity-20 xl:bg-contain #{!important};
        .icon_item {
            @apply container h-full;
            .b_content {
                @apply w-full p-4 xl:w-3/4 xl:pt-24 xl:pb-12 xl:px-12 font-medium;
                .box_title {
                    @apply text-[2rem] font-semibold;
                }

                .box_content {
                    @apply text-justify text-lg font-medium;
                    h4 {
                        @apply py-4;
                    }

                    ul {
                        @apply border-t-2 border-white py-4 flex flex-row auto-cols-2fr xl:space-x-24 space-x-12;
                    }
                }
            }
        }
    }
}

._section_success_story {
    @apply w-full;
    .block_content {
        @apply py-6 pb-4 lg:py-12 lg:pb-8;
    }

    .box_heading {
        @apply xl:pt-24 text-white text-4xl font-bold;
    }

    .text_content {
        @apply w-7/12 py-4 mx-auto text-white text-xl font-bold;
    }

    .icon_item {
        @apply container flex flex-row py-4 flex-wrap gap-4 xl:gap-8 ;
        .b_content {
            @apply bg-black rounded-tl-4xl rounded-br-4xl text-white px-4 py-4 xl:py-8 text-center flex-1;
            .box_content {
                @apply px-2 xl:px-0;
            }
        }

        .box_title {
            @apply xl:text-[2.5rem] leading-tight text-2xl font-bold py-4 text-[#01c29a];
        }
    }

    .cta_content {
        @apply text-center;
    }
}

._section_look_past {
    @apply py-12;
    .block_content {
        @apply container;
    }

    ._boxstyle_image_button {
        @apply text-white;
        .box_heading {
            @apply text-white text-3xl font-bold;
        }

        .box_content {
            @apply py-4 px-4 xl:px-40 text-xl text-center;
        }

        .box_icon_inside {
            .box_item {
                @apply flex flex-col-reverse xl:grid xl:grid-cols-12 content-center gap-12 ;
                .list_button {
                    @apply relative gap-8 col-end-13 col-start-9 flex flex-col gap-8 before:content-[''] before:absolute before:w-[2px] before:bg-black before:bg-opacity-20 before:left-[27px] before:h-1/2;
                    //&:before {
                    //    content: "";
                    //    position: absolute;
                    //    width: 2px;
                    //    background: rgba(0, 0, 0, 0.2);
                    //    left: 27px;
                    //    top: 1rem;
                    //    height: calc(100% - 2.5rem);
                    //}
                }

                .list_icon {
                    @apply col-start-1 col-end-9 flex bg-black justify-center items-center p-6 rounded-xl;
                    .icon {
                        @apply flex-1 ;
                    }
                }

                .button_content {
                    @apply hover:before:bg-white relative pl-14 py-4 pr-6 bg-white text-gray-dark rounded-md shadow-md text-left font-medium hover:text-white hover:bg-gradient-to-br hover:from-[#01c29a] hover:to-[#01c29a]  active:text-white active:bg-gradient-to-br active:from-[#01c29a] active:to-[#01c29a];

                    &:before {
                        @apply content-[''] block absolute left-[22px] top-1/2 -translate-y-1/2 w-[12px] h-[12px] rounded-full bg-[#1296B0];
                    }
                }

                .active {
                    @apply text-white bg-gradient-to-br from-[#01c29a] to-[#01c29a] before:bg-white;
                }

            }
        }
    }
}

.box_sample_reports {
    @apply py-4;
    .report_item {
        @apply shadow bg-white my-4 px-6 py-8;
        .b_heading {
            @apply text-3xl px-8;
            .subheader {
                @apply text-xl pt-0 pb-2;
            }
        }

        .box_content {
            h5 {
                @apply border-b-gray-200 font-bold border-b text-base;
            }

            h6 {
                @apply text-lg py-2 font-light;
            }

            ul {
                @apply list-outside list-disc;
                li {
                    @apply ml-4 text-justify;
                }
            }
        }
    }

    .btn_sampleReport {
        @apply my-2 text-white bg-[#01c29a] hover:bg-[#01c29a] py-2 px-4 xl:px-12 rounded ;
    }
}

.modal_sampleReport {
    .modalInnerSampleReport {
        @apply max-w-full xl:w-1/2 w-full mx-auto text-left shadow-lg;
        .lead_cover {

            img {
                @apply object-cover h-64  rounded-md shadow items-center;
            }
        }
    }
}

.talent-development {
    ._section_4_cols {
        .icon_item {
            .box_title {
                @apply text-[#1c5ad2] #{!important};
            }
        }
    }

    ._section_look_past .button_content {
        @apply before:bg-[#1c5ad2] hover:before:bg-white hover:bg-gradient-to-br hover:from-[#1c5ad2] hover:to-[#1c5ad2]  #{!important};
    }

    ._section_look_past ._boxstyle_image_button .box_icon_inside .box_item .active {
        @apply before:bg-white bg-gradient-to-br from-[#1c5ad2] to-[#1c5ad2]  #{!important};
    }

    .benefit_heading .box_content li {
        @apply before:bg-[#1c5ad2] #{!important};
    }

    .diff-plus span {
        @apply bg-[#1c5ad2] #{!important};
    }

    ._section_success_story {
        .box_title {
            @apply text-[#1c5ad2];
        }
    }
}

.career-compass {
    ._slogan_banner_home {
        .heading, .subheading {
            @apply leading-relaxed py-3;
        }

        .b_content {
            h6 {
                @apply leading-relaxed text-white text-center xl:text-5xl text-2xl font-bold #{!important} ;
            }
        }
    }
}

._article_content {
    .ck-content {
        p, h1, h2, h3, h4 {
            @apply pb-2;
        }
    }
}

._related_artile {
    .splide__arrow svg {
        //@apply fill-gray-700;
        @apply fill-[#000];
    }

    .splide__arrow--prev {
        @apply right-[4em];
    }

    .splide__arrow {
        @apply p-3 fill-current  border shadow;
        left: unset !important;
    }
}


.block_register {
    @apply py-6;
}
