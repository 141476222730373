@tailwind base;
@tailwind components;
@tailwind utilities;

@import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';
@import 'animate.css/animate.compat.css';

@layer utilities {
    .placeholder-italic::placeholder {
        @apply italic
    }
}

@layer base {
    h1 {
        @apply text-2xl;
    }

    h2 {
        @apply text-xl;
    }

    h3 {
        @apply text-lg;
    }

}

//Fontawesome
@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/brands.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/regular.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/solid.scss";

@import 'frontend.scss';
@import 'ck_editor.scss';
@import 'banner.scss';
