.banner_footer_slogan {
    @apply text-xl lg:text-3xl text-center mx-auto lg:pb-5 px-3 text-white font-semibold h-[15rem] py-4 lg:pt-10 w-full;
    .b_button {
        @apply mt-4 mb-3;
        a {
            @apply bg-white rounded text-blue  hover:bg-blue hover:text-white;
        }
    }

    .b_content {
        @apply flex flex-col justify-center h-full;
    }

}

.b_intro {
    p {
        @apply mt-4;
    }
}

.btn-gradient {
    @apply bg-gradient-to-r from-[#1839b2] to-[#0057c8] rounded-lg px-3 py-1 lg:px-10 lg:py-2 text-white uppercase font-bold my-2 lg:my-5 hover:from-[#01c29a] hover:to-[#1839b2] hover:text-white;
}

.slide_figure {
    @apply min-h-[128px];
}

.slide_figcaption {
    .slogan {
        @apply flex justify-items-center flex-col justify-center items-center;
        .b_content {
            @apply text-center justify-center text-white;
        }

        .heading {
            @apply py-3 leading-snug text-white text-2xl xl:text-5xl font-bold text-center justify-center;
        }

        .subheading {
            @apply py-3 leading-snug  text-center text-white text-xl xl:text-3xl font-bold text-center justify-center;
        }

        .b_url {
            @apply mx-auto text-center;
        }
    }
}

.slogan_home {
    @apply py-2;
    .subheading {
        @apply text-[#0057c8] text-2xl font-bold pt-3 pb-5;
    }

    .b_content {
        p {
            @apply px-4 md:px-2 pb-2;
            br {
                @apply hidden md:block;
            }
        }
    }
}

._banner_solutions {
    .heading {
        @apply text-2xl lg:text-5xl font-bold;
    }

    .subheading {
        @apply text-4xl font-bold;
    }

    .b_content {
        @apply text-lg lg:text-xl;
    }
}

.slide_figcaption {
    @apply absolute bottom-0 top-0 left-0 right-0;
}

.slide_figcaption {
    ._banner_webinar {
        @apply flex items-center h-full flex-col gap-8;
        .b_image {
            @apply p-0;
            img {
                @apply h-2/3 w-max object-scale-down;
            }
        }

        ._banner_primary {
            @apply self-center justify-self-center mx-auto max-w-lg lg:max-w-xl xl:max-w-2xl;
        }
    }
}

._banner_home {
    .slide_figcaption {
        @apply bottom-1/2 top-1/3 xl:top-1/2 left-0 right-0;
    }

    .slide_picture {
        @apply h-72 lg:h-96 xl:h-128;
        .slide_img {
            @apply max-h-full;
        }
    }

    ._slogan_banner_home {
        .heading, .subheading {
            br {
                @apply hidden md:block;
            }
        }
    }
}

._banner_text_bottom {
    .slide_figcaption {
        @apply xl:text-5xl md:text-3xl text-2xl text-white font-bold text-center flex items-end justify-center xl:mb-20 lg:mb-10 mb-5;
    }
}

._banner_talent_acquisition {
    .slide_picture {
        @apply h-72 lg:h-96 xl:h-128;
        .slide_img {
            @apply max-h-full;
        }
    }

    .slide_figcaption {
        @apply text-center text-white text-4xl font-bold bottom-1/2 top-1/4 xl:top-1/3 left-0 right-0;
    }
}

.banner_with_icon {
    @apply container px-0 w-full xl:w-8/12 min-h-fit h-full max-h-full content-center flex flex-col justify-center py-5 text-center text-white xl:text-3xl;

    .b_image {
        @apply flex justify-center;
    }

    .b_content {
        h5 {
            @apply leading-relaxed xl:text-5xl md:text-3xl text-lg py-4 font-normal;
            strong {
                @apply leading-snug;
            }
        }
    }

}
